import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
import { MdErrorOutline } from "react-icons/md";

function ErrorFallbackScreen(){
    function handleReaload(){
        window.location.reload();
    }

    function handleGoBack(){
        window.history.back();
        window.location.reload();
    }

    return (
        <div className="h-screen w-screen items-center flex  justify-center flex-col gap-12">
            <MdErrorOutline className="text-[150px] text-blue-500"/>
            <h1 className="text-2xl text-center max-w-[400px]">Oops! Something went wrong. Please reaload the page or go back</h1>
            <div className="flex gap-4">
                <DarkBlueButton handleClick={handleReaload} name="Reload"/>
                <DarkBlueButton handleClick={handleGoBack} name="Go back"/>
            </div>
        </div>
    );
};

export default ErrorFallbackScreen;