import React, { useEffect, useState } from "react";

import CountUp from "react-countup";
import apiClient from "../../lib/apis/fetch";
import { getRouteSegment } from "../../lib/utils/funcs";
import { baseURL } from "../../lib/utils/constants";

const DashboardCard = ({
  hospitalID,
  doctor_id,
  name,
}: {
  hospitalID: string;
  doctor_id: string;
  name: string;
}) => {
  const [todaysBookings, setTodaysBookings] = useState<number>();
  const hospital_id = getRouteSegment(1);
  const [typeBooking, setTypeBooking] = useState("today");

  useEffect(() => {
    const getBookingsCount = async () => {
      const response = await apiClient.get(
        `${baseURL}/analytics/booking-stats`,
        {
          params: {
            hospital_id: hospital_id,
          },
        }
      );
      if (response?.status === 200) {
        if (typeBooking === "today") {
          setTodaysBookings(response.data.result.today_bookings);
        } else {
          setTodaysBookings(response.data.result.lifetime_bookings);
        }
        console.log(response.data.result.lifetime_bookings);
      }
      return response;
    };
    getBookingsCount();
  }, [typeBooking, hospital_id]);

  return (
    <div
      className="flex flex-col items-center justify-center w-full  bg-white rounded-lg px-4 py-5 lg:px-5 lg:py-6"
      data-aos="zoom-in"
      data-aos-duration="1000"
    >
      <div className="text-center h-20">
        <p className="text-4xl lg:text-4xl text-dark tracking-[0.4px] font-bold">
          <CountUp
            duration={2}
            className="counter"
            end={Number(
              todaysBookings === undefined ? "0" : String(todaysBookings)
            )}
          />
        </p>
        <p className="text-sm lg:text-sm text-dbGray tracking-[0.2px] mt-2 font-medium">
          {typeBooking === "today" ? name : "Overall Bookings"}
        </p>
      </div>
      <div className="flex mt-4 md:mt-8 w-full">
        {typeBooking === "overall" ? (
          <button
            className="bg-queueHover border-none p-4 rounded-full text-sm text-white font-medium w-full rounded-r-none"
            onClick={() => {
              setTypeBooking("overall");
            }}
          >
            Overall bookings
          </button>
        ) : (
          <button
            className="bg-[#F5F5F5] border-none p-4 rounded-full text-sm text-gray-500 font-medium w-full rounded-r-none"
            onClick={() => {
              setTypeBooking("overall");
            }}
          >
            Overall bookings
          </button>
        )}
        {typeBooking === "today" ? (
          <button
            className="bg-queueHover border-none p-4 rounded-full text-sm text-white font-medium w-full rounded-l-none"
            onClick={() => {
              setTypeBooking("today");
            }}
          >
            Todays bookings
          </button>
        ) : (
          <button
            className="bg-[#F5F5F5] border-none p-4 rounded-full text-sm text-gray-500 font-medium w-full rounded-l-none"
            onClick={() => {
              setTypeBooking("today");
            }}
          >
            Todays bookings
          </button>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
