// Error boundaries catch errors during rendering, in lifecycle methods. Hence we are using class-based React code here.
// This component prevents the blank screen when an error occurs in the application.

import { Component, ErrorInfo, ReactNode } from "react";
import ErrorFallbackScreen from "../../screens/ErrorFallbackScreen";

interface ErrorBoundaryProps {
    children: ReactNode;
}


interface ErrorBoundaryState {
    hasError: boolean;
}

// Create the ErrorBoundary class component
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    // Update state when an error is thrown
    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    // Log error information to the console
    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error("Uncaught error:", error, errorInfo);
    }

    // Render fallback UI if an error is caught, otherwise render children
    render() {
        if (this.state.hasError) {
            return <ErrorFallbackScreen/>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;