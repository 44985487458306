import React from 'react';

interface SpinnerLoaderProps {
    size?: 'small' | 'medium' | 'large';
}

const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({ size = 'medium' }) => {
    const sizeClasses = {
        small: 'w-4 h-4 border-2',
        medium: 'w-8 h-8 border-4',
        large: 'w-12 h-12 border-6',
    };

    return (
        <div className={`spinner-loader border-t-transparent border-solid rounded-full animate-spin ${sizeClasses[size]}`}>
        </div>
    );
};

export default SpinnerLoader;